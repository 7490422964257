import CharlesMeta from 'components/common/meta/CharlesMeta';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Layout } from 'components/common';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Section from 'components/common/Section';
import StructuredWebPage from 'components/common/meta/StructuredWebPage';
import ZeezooWonders from 'images/zeezoo/zeezoo-10-wonders.svg';
import Image from 'components/common/utils/Image';
import { Link } from 'gatsby';
import { Button } from 'react-bootstrap';
import Assistance from "images/icons/start/small-assistance.svg";
import Enveloppe from 'images/new-images/menu-envelope.svg';
import './styles.sass';

const NotFoundPage = () => (
  <Layout showBars showLinksInFooter={false}>
    <CharlesMeta
      data={{
        title: 'Page non trouvée',
        description:
          'Charles.co est une plateforme de téléconsultation dédiée à la santé intime. Sur Charles, téléconsultez un médecin sexologue, faites-vous livrer depuis une pharmacie.',
      }}
    />
    <StructuredWebPage
      title={'Page non trouvée'}
      description={'Charles.co est une plateforme de téléconsultation dédiée à la santé intime. Sur Charles, téléconsultez un médecin sexologue, faites-vous livrer depuis une pharmacie.'}
    />
    <div className="text-center text-sm-left">
      <Section className="">

        <Container className="mb-5">
          <div className="d-flex justify-content-center mt-5">
            <Image filename="new-images/new-404.svg"
              className="mw-100" />
          </div>
          <div className="h3">Oups !</div>

          <div className="d-flex justify-content-center" >
            <div className=" w-50 text-center">
              <p>Désolé mais ce lien est introuvable. Mais pas de problème, vous pouvez revenir à l’accueil ou nous contacter si vous avez des questions.</p>
            </div>
          </div>
          <div className="d-flex justify-content-center w-100" >
            <div className="text-center d-flex flex-column align-items-center flex-md-row justify-content-between mt-4">
              <Link to="/">
                <Button className="black-404-btn mb-3  mb-md-0 d-flex justify-content-center">Retourner à la page d'accueil</Button>
              </Link>
              <Link to="/faq/">
                <Button className="white-404-btn ml-md-5">Consulter la FAQ</Button>
              </Link>
            </div>
          </div>
          <div className="d-block mt-4">
            <div className="d-flex justify-content-center">
              <p>Pour toute question vous pouvez joindre notre équipe :</p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column flex-sm-row">
              <div className=""> <Assistance /><span className="ml-2">01 86 65 17 33</span></div>
              <div className="ml-3"><Enveloppe /> <span className="ml-2">contact@charles.co</span></div>
            </div>
          </div>
        </Container>
      </Section>
    </div>
  </Layout>
);

export default NotFoundPage;
